import React from "react"
import classNames from "classnames"
import logo from "components/_ui/Logo/promidon-logo-dark.svg"
import "./Logo.scss"

const Logo = ({ className }) => (
  <div className={classNames("Logo", className)}>
    <img src={logo} alt="Promidon Inc." />
  </div>
)

export default Logo
